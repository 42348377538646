<template>
  <div class="page-container">
    <div class="block-box">
      <div class="block-title">
        <div class="block-title-left">创建团队</div>
      </div>
    </div>
    <div class="create-group-form">
      <van-form ref="creategroupform" @submit="handleCreateGroup">
        <van-field
          v-model="projectName"
          name="项目名称"
          label="项目名称"
          readonly
        />
        <van-field
          v-model="group.name"
          name="团队名称"
          label="团队名称"
          placeholder="请填写团队名称"
          maxlength="14"
          show-word-limit
          :rules="[{ required: true, message: '请填写该字段' }]"
        />
        <van-field
          v-model="group.leader"
          name="团队队长"
          label="团队队长"
          placeholder="请填写团队队长"
          maxlength="6"
          show-word-limit
          :rules="[{ required: true, message: '请填写该字段' }]"
        />
        <van-field
          v-model="group.aim"
          name="目标金额"
          label="目标金额"
          placeholder="设置目标金额"
          type="number"
          :rules="[{ required: true, message: '请填写该字段' }]"
        />
        <van-field
          v-model="group.message"
          name="团队口号"
          label="团队口号"
          placeholder="写下团队口号"
        />
      </van-form>
      <van-cell title="团队标志">
        <template #default>
          <div style="text-align: left">
            <van-button size="mini" type="danger" plain @click="uploadLogo"
              >上传图片</van-button
            >
            <van-button
              v-if="selectLogo"
              size="mini"
              plain
              @click="selectLogo = null"
              >删除图片</van-button
            >
          </div>
        </template>
      </van-cell>
      <van-cell title="标志预览" v-if="selectLogo">
        <template #default>
          <div style="text-align: left">
            <van-image width="140" height="140" :src="selectLogo" />
          </div>
        </template>
      </van-cell>
      <p style="text-align: left; font-size: 0.7rem; margin-left: 1rem" v-else>
        标志:建议上传正方形图片,不上传默认使用您的头像作为团队标志
      </p>
      <div style="margin: 16px">
        <van-button
          round
          block
          type="info"
          native-type="submit"
          color="red"
          @click="$refs.creategroupform.submit()"
        >
          创建
        </van-button>
      </div>
    </div>
    <sdut-banner />
  </div>
</template>

<script>
import SdutBanner from "@/components/SdutBanner";
import { getProjectTitle } from "@/api/project";
import { createGroup } from "@/api/group";
import wxSdk from "@/utils/wxsdk";
export default {
  components: { SdutBanner },
  data() {
    return {
      group: {
        name: null,
        aim: null,
        message: null,
        project: null,
        leader: null,
        avatar: null,
      },
      projectName: "loading..",
      selectLogo: null,
    };
  },
  mounted() {
    this.group.project = this.$route.query.project;
    this.fetchData();
  },
  methods: {
    fetchData() {
      getProjectTitle(this.group.project).then((response) => {
        this.projectName = response.title;
      });
    },
    handleCreateGroup() {
      if (this.selectLogo) {
        this.group.avatar = this.selectLogo;
      }
      createGroup(this.group).then((response) => {
        //this.$router.push({ path: `/group/${response.group}` });
        window.location.replace(`/group/${response.group}`)
      });
    },
    uploadLogo() {
      wxSdk.uploadImage().then((path) => {
        this.selectLogo = path;
      });
    },
  },
};
</script>

<style>
.create-group-form {
  background-color: #ffffff;
}
</style>
